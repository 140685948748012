
import { defineComponent, PropType } from 'vue'

interface ImgProps {
  id: string,
  imgUrl: string,
  imgLink?: string
}



export default defineComponent({
  name: 'MomaiSwipe',
  props: {
    imgs: {
      type: Array as PropType<ImgProps[]>,
      required: true
    },
    imgStyle: {
      type: String
    },
  },
  setup(props, {emit}) {

    const handleDetail = (index: number) => {
      emit('handleDetail', index)
    }
    return {
      handleDetail
    }
  }
})
